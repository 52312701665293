@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

.task-list-item::before {
  @apply hidden;
}

.task-list-item {
  @apply list-none;
}

.footnotes {
  @apply pt-8 mt-12 border-t border-gray-200 dark:border-gray-700;
}

.csl-entry {
  @apply my-5;
}

.hr {
  @apply my-2 w-full animate-pulse border-none text-center h-10 before:content-['∿∿∿'] before:text-gray-700 dark:before:text-gray-200 before:text-2xl;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-scrollbar {
  @apply w-1 h-1;
}

::-webkit-scrollbar-thumb {
  @apply rounded-md bg-primary-500;
}

.prose .anchor {
  @apply absolute invisible;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply visible no-underline;
}

.prose a {
  @apply text-primary-700;
}

.prose a:not(.anchor):not(.remove-underline) {
  @apply transition-all no-underline relative inline-block;
}

.prose a:not(.anchor):not(.remove-underline):after {
  @apply block border-b-2 border-primary-500 transform scale-x-0 transition-all duration-300 origin-center;
  content: '';
}

.prose a:not(.anchor):not(.remove-underline):hover:after {
  @apply transform scale-x-100 origin-right;
  content: '';
}

.prose .anchor:after {
  @apply text-slate-300 dark:text-slate-700;
  content: '#';
}

.prose *:hover > .anchor {
  @apply visible no-underline;
}

.prose h1,
h2,
h3 {
  @apply scroll-mt-24;
}

.prose table {
  @apply overflow-x-auto block;
}

.prose ul > li::marker {
  @apply text-primary-500 text-xl;
}

.prose ol > li::marker {
  @apply text-primary-500;
}

.step > h3 {
  @apply my-0;
}

#kbar-listbox {
  @apply my-3;
}

#carbonads {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  @apply w-full;
}

#carbonads > span {
  @apply relative block w-full rounded-md dark:bg-gray-800 bg-gray-200 p-3 shadow-sm;
}

#carbonads .carbon-wrap {
  @apply flex;
}

#carbonads .carbon-img {
  @apply mr-3 rounded-md;
}

#carbonads .carbon-text {
  @apply mb-3 text-sm leading-6 text-gray-900 dark:text-gray-100 xl:text-xs;
}

#carbonads .carbon-poweredby {
  @apply absolute bottom-0 right-0 flex justify-end p-2 text-[8px] uppercase tracking-wider text-gray-800 dark:text-gray-200 font-semibold;
}

.giscus,
.giscus-frame {
  width: 100%;
}
